<template>
    <div class="content-wrapper escrow-browser">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section title="Search Receipts" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" @keyup.enter="onSearch" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="bankFilterDisplay != 'All'">
                            <div class="filter-name">Bank:</div>
                            <div class="filter-value">{{bankFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="escrowUnitFilterDisplay != 'All'">
                            <div class="filter-name">Escrow Unit:</div>
                            <div class="filter-value">{{escrowUnitFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="statusFilterDisplay != 'All'" v-rq-tooltip.html.hover.top :title="statusFilterTooltip">
                            <div class="filter-name">Status:</div>
                            <div class="filter-value">{{statusFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="typeFundsFilterDisplay != 'All'" v-rq-tooltip.html.hover.top :title="typeFundsFilterTooltip">
                            <div class="filter-name">Type of Funds:</div>
                            <div class="filter-value">{{typeFundsFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="activeRequest.ordersID > 0">
                            <div class="filter-name">File:</div>
                            <div class="filter-value">{{activeRequest.gfNo}}</div>
                        </li>
                        <li class="nav-item" v-if="receiptFilterDisplay.length > 0">
                            <div class="filter-name">Receipt#:</div>
                            <div class="filter-value">{{receiptFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="amountFilterDisplay.length > 0">
                            <div class="filter-name">Amount:</div>
                            <div class="filter-value">{{amountFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="dateFilterDisplay.length > 0">
                            <div class="filter-name">Date:</div>
                            <div class="filter-value">{{dateFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onSearchClearAll">Clear All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search_clear"
                                variant="theme"
                                @click="onSearchClear">Clear</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search_checks"
                                variant="theme"
                                :disabled="errorMessage.length > 0 && !hasFilter || fileNumberInvalid"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div class="col col-3 col-xl-3 form-group">
                    <label for="dtp_escrow_bank">Escrow Account</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_escrow_bank', id: 'dtp_escrow_bank' }"
                        :items="escrowBanks"
                        value-expr="id"
                        display-expr="name"
                        v-model="request.bankCompanyID"
                        placeholder="All Escrow Accounts..."
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="dtp_escrow_unit">Escrow Unit</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_escrow_unit', id: 'dtp_escrow_unit' }"
                        :items="escrowUnits"
                        value-expr="id"
                        display-expr="name"
                        v-model="request.escrowUnitID"
                        placeholder="All Escrow Units..."
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="dtp_status">Status</label>
                    <dx-tag-box
                        automation_id="dtp_status"
                        ref="tb_statuses"
                        class="form-control"
                        :data-source="depositStatuses"
                        display-expr="name"
                        value-expr="id"
                        :search-enabled="true"
                        :show-selection-controls="true"
                        :show-clear-button="true"
                        :max-displayed-tags="3"
                        :show-drop-down-button="true"
                        placeholder="All Statuses..."
                        apply-value-mode="useButtons"
                        v-model:value="request.depositStatusIDs"
                    />
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="dtp_status">Type of Funds</label>
                    <dx-tag-box
                        automation_id="tb_type_fund_ids"
                        ref="tb_tags"
                        class="form-control"
                        :data-source="fundTypes"
                        display-expr="name"
                        value-expr="id"
                        :search-enabled="true"
                        :show-selection-controls="true"
                        :show-clear-button="true"
                        :max-displayed-tags="3"
                        :show-drop-down-button="true"
                        placeholder="All Fund Types..."
                        apply-value-mode="useButtons"
                        v-model:value="request.typeFundIDs"
                    />
                </div>
                <div class="col col-2 col-xl-2">
                    <file-number-input
                        automation_id="txt_gfno"
                        label="File #"
                        v-model:order-id="request.ordersID"
                        v-model:has-error="fileNumberInvalid"
                        v-model="request.gfNo"
                        show-clear-button
                        show-search-button
                    />
                </div>
                <div class="col col-4 col-xl-4 form-group">
                    <label for="txt_check_date_range">Date Range</label>
                    <rq-date-range
                        id="txt_check_date_range"
                        v-model:start-date="request.depositDateFrom"
                        v-model:end-date="request.depositDateTo"
                        match-value="before-clear"
                        match-field="end"
                        format="MM/dd/yyyy"
                        type="date"
                        show-calendar-drop-downs
                        show-clear-buttons
                        no-calendars
                        borderless
                    />
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="txt_deposit_amount">Amount Range</label>
                    <rq-number-range
                        automation_id="txt_deposit_amount"
                        prepend-icon="fas fa-dollar-sign"
                        :decimals="2"
                        v-model:start-value="request.depositAmountFrom"
                        v-model:end-value="request.depositAmountTo"
                        match-value="before-clear"
                        match-field="end"
                        show-clear-buttons
                        input-groups
                        allow-nulls
                        no-prefix
                        commas
                    />
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="txt_receipt_id">Receipt ID Range</label>
                    <rq-number-range
                        automation_id="txt_receipt_id"
                        :commas="false"
                        :decimals="0"
                        v-model:start-value="request.depositReceiptIDFrom"
                        v-model:end-value="request.depositReceiptIDTo"
                        match-value="before-clear"
                        match-field="end"
                        input-groups
                        show-clear-buttons
                        allow-nulls
                        no-prefix
                    />
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_escrow_deposits"
            :actions="selectionActions"
            :config="gridConfig"
            title="Receipts"
            title-size="sm"
            class="grid-container"
            :data-source="gridDataSource"
            @edit="onEditItem"
            @change-reconciliation-date="onChangeReconciliation"
            @change-date="onChangeDate($event, 'depositDate')"
            @change-verified-date="onChangeDate($event, 'depositVerified')"
            @delete="onDeleteItem"
            @navigate="onGotoFile"
            @rowDoubleClick="onEditItem"
            export-file-name="deposits_data"
            :strikethrough-if-true="['inactive']"
            hide-show-column-chooser
            integrated-search
            :hide-search="items.length == 0"
            rq-filters>
            <template #toolbar>
                <ul class="nav">
                    <li class="nav-item" v-rq-tooltip.html.hover.top :title="`${(readOnly || !localSecurity.AllowAccessToQuickCheckQuickDepositEntry) ? 'Access Restricted' : 'Add New Receipt'}`">
                        <b-button
                            automation_id="btn_add"
                            variant="theme"
                            @click="onAddDeposit"
                            :disabled="readOnly || !localSecurity.AllowAccessToQuickCheckQuickDepositEntry"
                            >Add
                        </b-button>
                    </li>
                    <li class="nav-item">
                        <rq-report-button
                            text="View Report"
                            :disabled="readOnly || !hasActiveFilter || fileNumberInvalid || items.length == 0 || hasFilterChanged"
                            :path="reportOptions.path"
                            :name="reportOptions.title"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { ORDER_ACTIONS } from '@/store/actions';
    import { EscrowDepositDto, EscrowDepositSearchRequest }  from "../models";
    import { DepositStatus, FundOption } from '../../file/check-writing/enums';
    import { SystemLookupItem } from "@/shared/models/models";
    import FileNumberInput from "@/shared/components/rq/FileNumberInput";
    import QuickDepositForm from "../components/QuickDepositForm";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { UserScreenAccessLevel } from "@/shared/models/enums";
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import { DateTime } from "luxon";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { isDepositDateBeforeLastRecon, getIsDateDisabledFunction } from "../helpers";

    const DEFAULT_ERROR_MESSAGE = "Please correct the highlighted errors on screen to continue.";
    const INVALID_FILE_ERROR_MESSAGE = "Invalid File Number";
    const FINALIZED_RECON_WARNING = "<div class='alert alert-danger mt-1'><svg class='rq-icon-symbol-lg alert-icon pe-1'><use href='#rq-fas-exclamation-triangle'></use></svg>One or more Item is dated within a finalized reconciliation time period and will recalculate affected finalized reconciliations.</div>";

    export default {
        name: "DepositBrowserList",
        mixins: [ GridSystemLookupMixin ],
        components: { FileNumberInput },
        data () {
            return {
                items: [],
                request: new EscrowDepositSearchRequest(),
                activeRequest: new EscrowDepositSearchRequest(),
                errorMessage: "",
                selectionActions: [],
                filtersExpanded: true,
                fileNumberInvalid: false
            };
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            hasFilter(){
                return !_.isEqual(new EscrowDepositSearchRequest(this.request), new EscrowDepositSearchRequest());
            },
            hasActiveFilter(){
                return !_.isEqual(new EscrowDepositSearchRequest(this.activeRequest), new EscrowDepositSearchRequest());
            },
            hasFilterChanged(){
                return !_.isEqual(new EscrowDepositSearchRequest(this.activeRequest), new EscrowDepositSearchRequest(this.request));
            },
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowDepositDelete",
                    "AllowFinalizedReconModifications",
                    "AllowEscrowAdminBrowserChange",
                    "AllowAccessToQuickCheckQuickDepositEntry",
                    "CanDeleteBookedDeposits",
                    "CheckWriting_ScreenAccess",
                    "DepositSlip_ScreenAccess",
                    "DepositPreDateDays",
                    "DepositPostDateDays",
                ]);
            },
            dateFilterDisplay(){
                if (_.isNil(_.get(this, "request.depositDateFrom")) && _.isNil(_.get(this, "request.depositDateTo"))) {
                    return "";
                }
                return `${this.getDate(this.request.depositDateFrom, "Anything")} to ${this.getDate(this.request.depositDateTo, "Anything")}`;
            },
            amountFilterDisplay(){
                if (_.getNumber(this, "request.depositAmountFrom", 0) > 0 && _.getNumber(this, "request.depositAmountTo", 0) > 0) {
                    return `$${this.request.depositAmountFrom} to $${this.request.depositAmountTo}`;
                }
                if (_.getNumber(this, "request.depositAmountFrom", 0) == 0 && _.getNumber(this, "request.depositAmountTo", 0) > 0) {
                    return `Anything to $${this.request.depositAmountTo}`;
                }
                if (_.getNumber(this, "request.depositAmountFrom", 0) > 0 && _.getNumber(this, "request.depositAmountTo", 0) == 0) {
                    return `$${this.request.depositAmountFrom} to Anything`;
                }
                return "";
            },
            receiptFilterDisplay(){
                if (_.getNumber(this, "request.depositReceiptIDFrom", 0) > 0 && _.getNumber(this, "request.depositReceiptIDTo", 0) > 0) {
                    return `${this.request.depositReceiptIDFrom} to ${this.request.depositReceiptIDTo}`;
                }
                if (_.getNumber(this, "request.depositReceiptIDFrom", 0) == 0 && _.getNumber(this, "request.depositReceiptIDTo", 0) > 0) {
                    return `Anything to ${this.request.depositReceiptIDTo}`;
                }
                if (_.getNumber(this, "request.depositReceiptIDFrom", 0) > 0 && _.getNumber(this, "request.depositReceiptIDTo", 0) == 0) {
                    return `${this.request.depositReceiptIDTo} to Anything`;
                }
                return "";
            },
            bankFilterDisplay(){
                return _.isNil(this.request.bankCompanyID) ? "All" : this.lookupHelpers.getLookupItemName(this.lookupItems.ESCROW_ACCOUNTS, this.request.bankCompanyID);
            },
            escrowUnitFilterDisplay(){
                return _.isNil(this.activeRequest.escrowUnitID) ? "All" : this.lookupHelpers.getLookupItemName(this.lookupItems.ESCROW_UNITS, this.activeRequest.escrowUnitID);
            },
            statusFilterDisplay(){
                let itemNames = _.map(this.activeRequest.depositStatusIDs, id => _.find(this.depositStatuses, { id }).name);
                if (_.size(itemNames) == 0) return "All";
                if (_.size(itemNames) > 2) return `${itemNames.length} Selected`;
                return _.join(_.sortBy(itemNames, ['name']), ", ");
            },
            statusFilterTooltip(){
                let itemNames = _.map(this.activeRequest.depositStatusIDs, id => _.find(this.depositStatuses, { id }).name);
                if (_.size(itemNames) == 0) return "";
                return _.join(_.sortBy(itemNames, ['name']), ", ");
            },
            typeFundsFilterDisplay(){
                let itemNames = _.map(this.activeRequest.typeFundIDs, id => _.find(this.fundTypes, { id }).name);
                if (_.size(itemNames) == 0) return "All";
                if (_.size(itemNames) > 2) return `${itemNames.length} Types Selected`;
                return _.join(_.sortBy(itemNames, ['name']), ", ");
            },
            typeFundsFilterTooltip(){
                let itemNames = _.map(this.activeRequest.typeFundIDs, id => _.find(this.fundTypes, { id }).name);
                if (_.size(itemNames) == 0) return "";
                return _.join(_.sortBy(itemNames, ['name']), ", ");
            },
            readOnly() { return !this.localSecurity.AllowEscrowAdminBrowserChange ||  this.localSecurity.DepositSlip_ScreenAccess === UserScreenAccessLevel.Read; },
            userBankCompanyID(){
                let branchID = _.getNumber(this.lookupHelpers.getBranch(this.user.branchID), "bankCompanyID", 0);
                return branchID == 0 ? null : branchID;
            },
            reportOptions() {

                let reportOptions =
                    new ReportOptionsDto({
                    text: "Print",
                    reportPath: "System Reports\\Accounting\\Receipt Browser",
                    title: "Receipt Browser",
                    disabled: true,
                    immediate: true,
                    parameters: {
                        p_StartDate:            this.request.depositDateFrom,
                        p_EndDate:              this.request.depositDateTo,
                        p_Banks:                this.request.bankCompanyID || '',
                        p_DepositStatus:        this.request.depositStatusIDs || '',
                        p_OrderIDs:             this.request.ordersID || this.request.ordersID !== 0 ? this.request.ordersID : '' ,
                        p_TypeOfFunds:          this.request.typeFundIDs || '',
                        p_StartNumber:          this.request.depositReceiptIDFrom || null,
                        p_EndNumber:            this.request.depositReceiptIDTo || null,
                        p_MinAmount:            this.request.depositAmountFrom || null,
                        p_MaxAmount:            this.request.depositAmountTo || null
                    },
                });

                return reportOptions;
            },
        },

        watch: {
            fileNumberInvalid(newValue, oldValue) {
                if(newValue || newValue === oldValue || (this.errorMessage !== INVALID_FILE_ERROR_MESSAGE && this.errorMessage !== DEFAULT_ERROR_MESSAGE)) return;
                this.errorMessage = "";
            },
            "request.gfNo":{
                handler: function(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.fileNumberInvalid = false;
                    this.request.ordersID = 0;
                }
            },
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            setOrderID(){
                const self = this;
                if (_.isNil(this.request.gfNo) || this.request.gfNo === ""){
                    self.request.ordersID = 0;
                    self.activeRequest.ordersID = 0;
                } else {
                    self.request.ordersID = self.items.length === 0 ? 0 : self.items[0].ordersID;
                    self.activeRequest.ordersID = self.request.ordersID;
                }
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.EscrowAccountingApi.getDeposits(self.request);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new EscrowDepositDto(i));
                        self.activeRequest = new EscrowDepositSearchRequest(self.request);
                        self.setOrderID();
                    })
                    .catch(error => {
                        if (error.errorMessage == INVALID_FILE_ERROR_MESSAGE) {
                            self.fileNumberInvalid = true;
                        } else {
                            self.fileNumberInvalid = false;
                            console.error(error);
                            self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        }
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            fetchLookupData(depositID) {
                const self = this;
                let apiPromise = self.$api.EscrowAccountingApi.getLookups(depositID, "deposit");
                return self.$rqBusy.wait(apiPromise);
            },

            getDate(dateStr, nullValue = null) {
                return _.isNullOrEmpty(dateStr) ? nullValue : dateStr;
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Receipt";
                self.itemTypeNamePlural = "Receipts";
                self.itemKey = "depositID";
                self.depositStatuses = DepositStatus.lookupItems;
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.USER_ESCROW_ACCOUNTS);
                self.escrowUnits = self.lookupHelpers.getLookupItems(self.lookupItems.ESCROW_UNITS);
                self.fundTypes = self.lookupHelpers.getLookupItems(self.lookupItems.TYPE_FUNDS);
                self.request.bankCompanyID = self.activeRequest.bankCompanyID = self.userBankCompanyID;
                self.selectionActions = [
                    {
                        name: "edit",
                        text: "Edit",
                        eventName: "edit",
                        requireSelection: true,
                        disabled: function(e) {
                            // return (self.readOnly) ? 'Access Restricted' : false;
                        }
                    },
                    {
                        name: "change-status",
                        text: "Manage",
                        children: (self.readOnly) ? [] : [
                            { name: "change-date", text: "Deposit Date", eventName: "change-date", requireSelection: true, allowMultiSelection: true, disabled: function(e) { return self.readOnly || _.some(e.data, ['isEFER', true]) || _.some(e.data, ['isConsolidated', true]) || _.some(e.data, ['isReconciled', true]) || _.size(_.groupBy(e.data, "bankCompanyID")) > 1 || _.some(e.data, ['typeFundID', FundOption.None])} },
                            { name: "change-reconciliation-date", text: "Reconciliation Date", eventName: "change-reconciliation-date", requireSelection: true, allowMultiSelection: true, disabled: function(e) { return self.readOnly  || _.some(e.data, ['typeFundID', FundOption.None]); } },
                            { name: "change-verified-date", text: "Verified Date", eventName: "change-verified-date", requireSelection: true, allowMultiSelection: true, disabled: function(e) { return self.readOnly || _.some(e.data, ['isTransfer', true]) || _.some(e.data, ['isEFER', true]) || _.some(e.data, ['checkStatus', DepositStatus.Cleared]) || _.some(e.data, ['isConsolidated', true]) || _.some(e.data, ['isReconciled', true]) } },
                        ],
                        disabled(e) {
                            //if a user is not allowed to alter finalized recons then if the deposit date falls before the last recon date then it's locked
                            if (!self.localSecurity.AllowFinalizedReconModifications && _.some(e.data, isDepositDateBeforeLastRecon)) return "Access Restricted. One or more of the items selected is on a finalized recon.";
                            return (self.readOnly) ? 'Access Restricted' : false;
                        }
                    },
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.itemTypeName}`,
                        disabled: function(e) {
                            if (self.readOnly || !self.localSecurity.AllowDepositDelete) {
                                return "Access Restricted";
                            }
                            if (!self.localSecurity.CanDeleteBookedDeposits && _.some(e.data, ["depositStatus", DepositStatus.Booked])) {
                                return "Access Restricted: Unable to Delete Booked Deposits.";
                            }
                            if (_.some(e.data, ["isVerified", true])) {
                                return "One or more of the selected Deposits are Verified and cannot be deleted.";
                            }
                            if (!self.localSecurity.AllowFinalizedReconModifications && _.some(e.data, ["isReconciled", true])) {
                                return "One or more of the selected Deposits are Reconciled and cannot be deleted.";
                            }
                            return false;
                        }
                    },
                    { name: "goto", text: "Go To File", eventName: "navigate", requireSelection: true, tooltip: `Go to ${ self.localSecurity.CheckWriting_ScreenAccess ? `Check Writing` : `File Main`} for this File.` },
                ];
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    height: "100%",
                    remoteOperations: false,
                    columns: [
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "File#",
                            rqFilter: {
                                disabled: true,
                                disabledTooltip: "Use fields at the top of the page to filter this column."
                            }
                        },
                        {
                            dataField: "depositDate",
                            dataType: "date",
                            caption: "Date",
                        },
                        {
                            dataField: "payor",
                            dataType: "string",
                            cellTemplate: DxGridUtils.truncateCellTemplate,
                            width: 200
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            width: 100,
                            minWidth: 100
                        },
                        {
                            dataField: "description",
                            dataType: "string",
                            cellTemplate: DxGridUtils.truncateCellTemplate
                        },
                        {
                            dataField: "depositStatus",
                            dataType: "number",
                            caption: "Status",
                            lookup: {
                                dataSource: self.depositStatuses,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            ...DxGridUtils.lookupSortDisplayExpr,
                        },
                        {
                            dataField: "typeOfFundsDisplay",
                            dataType: "string",
                            caption: "Type of Funds",
                        },
                        {
                            dataField: "reconciliationDate",
                            dataType: "date",
                            caption: "Recon Date",
                            cellTemplate: DxGridUtils.reconDateCellTemplate
                        },
                        {
                            dataField: "depositSlipID",
                            dataType: "number",
                            caption: "Deposit Slip",
                        },
                        {
                            dataField: "depositVerified",
                            dataType: "date",
                            caption: "Verified Date",
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "bankCompanyID",
                                dataType: "number",
                                caption: "Escrow Account",
                                rqFilter: {
                                    disabled: true,
                                    disabledTooltip: "Use fields at the top of the page to filter this column."
                                }
                            },
                            lookupKey: self.lookupItems.ESCROW_ACCOUNTS
                        }),
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "escrowUnitID",
                                dataType: "number",
                                caption: "Escrow Unit",
                                rqFilter: {
                                    disabled: true,
                                    disabledTooltip: "Use fields at the top of the page to filter this column."
                                }
                            },
                            lookupKey: self.lookupItems.ESCROW_UNITS
                        }),
                        {
                            dataField: "usersDisplayName",
                            dataType: "string",
                            caption: "Person",
                            cellTemplate: DxGridUtils.truncateCellTemplate
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            onAddDeposit(e) {
                let item = new EscrowDepositDto();
                this.showQuickDepositDialog(item);
            },

            onChangeDate(e, dateName) {
                if(!e || !e.data) return;
                this.showChangeDateDialog(e.data, dateName);
            },

            onChangeReconciliation(e) {
                if(!e || !e.data) return;
                let items = e.data;
                let bankCompanyID = _.get(items[0], "bankCompanyID");
                if (_.every(items, ["bankCompanyID", bankCompanyID])) {
                    this.showReconciliationChangeDialog(items);
                } else {
                    this.$dialog.messageBox("WARNING", "You cannot change the reconciliation date of multiple deposits with different banks.");
                }
            },

            onGotoFile(e) {
                if(!e || !e.data) return;
                let orderId = _.parseNumber(e.data.ordersID, 0);
                let tab = 1;
                if(this.localSecurity.CheckWriting_ScreenAccess) {
                    this.$router.push({ name: "check-writing", params: { orderId, tab } });
                } else {
                    this.$router.push({ name: "oe:main", params: { orderId } });
                }
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                if (self.readOnly || !self.localSecurity.AllowDepositDelete) return;
                let items = e.data;
                let dateWarning = "";
                if (_.some(items, isDepositDateBeforeLastRecon)) {
                    dateWarning = FINALIZED_RECON_WARNING;
                }
                let orderId = 0;
                let hasEarnestMoney = _.some(items, ['description', 'Earnest Money']);
                if (hasEarnestMoney) {
                    let earnestDeposit = _.find(items, ['description', 'Earnest Money']);
                    orderId = _.get(earnestDeposit, "ordersID");
                }
                let ok = function (args) {
                    self.errorMessage = "";
                    let ids = _.map(items, self.itemKey);
                    let apiPromise = self.$api.EscrowAccountingApi.deleteDeposits(ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(data => {
                            let message = ids.length > 1 ? `${ids.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            self.fetchData();
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            return true;
                        })
                        .finally(() => {
                            if (hasEarnestMoney && orderId > 0) self.$store.dispatch(ORDER_ACTIONS.GET_ORDER, { orderId: orderId, refresh: true });//deleting earnest money affects the order, so refresh
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?${dateWarning}`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onEditItem(e) {
                if(!e || !e.data) return;
                this.errorMessage = "";
                this.showQuickDepositDialog(e.data);
            },

            onSearch() {
                this.errorMessage = "";
                if(!this.hasFilter) {
                    this.errorMessage = "Please enter at least one value to search Receipts";
                    return;
                }
                if(this.fileNumberInvalid) {
                    this.errorMessage = DEFAULT_ERROR_MESSAGE;
                    return;
                }
                this.fetchData();
            },

            onSearchClearAll() {
                this.onSearchClear();
                this.filtersExpanded = true;
            },

            onSearchClear() {
                this.request = new EscrowDepositSearchRequest();
                this.activeRequest = new EscrowDepositSearchRequest();
                this.items = [];
                this.refresh();
            },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },

            toastSaveSuccess(numSaved) {
                const self = this;
                let message = numSaved > 1
                    ? `${numSaved} ${self.itemTypeNamePlural} were saved.`
                    : `${self.itemTypeName} was saved.`;
                self.$toast.success(message);
            },

            async saveReconciliation(reconciliationID, ids){
                const self = this;
                if (self.readOnly) return;
                let apiPromise = self.$api.EscrowAccountingApi.saveDepositReconciliation(reconciliationID, ids);
                try {
                    await self.$rqBusy.wait(apiPromise);
                    self.toastSaveSuccess(ids.length);
                    self.fetchData();
                    return true;
                }
                catch(error) {
                    self.$toast.error("Error saving reconciliation.");
                    self.errorMessage = error.message;
                    return false;
                }
            },

            async saveDateChange(date, dateName, ids){
                const self = this;
                let apiPromise = self.$api.EscrowAccountingApi.saveDepositDateChange(date, dateName, ids);
                try {
                    await self.$rqBusy.wait(apiPromise);
                    self.toastSaveSuccess(ids.length);
                    self.fetchData();
                    return true;
                }
                catch(error) {
                    self.$toast.error("Error saving date change.");
                    self.errorMessage = error.message;
                    return false;
                }
            },

            getChangeDateDialogAlerts(items, dateName) {
                let result = [];
                let infoMessage = dateName === "depositVerified"
                    ? "Leave Blank to undo Verified Date"
                    : "";

                if(!_.isEmpty(infoMessage)) {
                    result.push({
                        variant: "info",
                        icon: "fas fa-exclamation-circle",
                        message: infoMessage,
                        position: "after"
                    });
                }

                if (_.some(items, isDepositDateBeforeLastRecon)) {
                    result.push({
                        variant: "danger",
                        icon: "fas fa-exclamation-triangle",
                        message: "One or more items are dated within a finalized reconciliation time period and will recalculate affected finalized reconciliations.",
                        position: "after"
                    });
                }

                return result;
            },

            showChangeDateDialog(items, dateName) {
                const self = this;
                if (self.readOnly) return;
                let ids = _.map(items, self.itemKey);
                let typeName = items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName;
                let count = items.length > 1 ? `${items.length} ` : "";

                let label = dateName === "depositVerified" ? "Verified Date" : "Deposit Date";
                let title = `Set ${label} - ${count}${typeName}`;
                let inputId = `dtp_${_.toLower(_.snakeCase(label))}`;
                let isRequired = dateName === "depositDate";

                let editorOptions = {};
                if (dateName === "depositDate") {
                    let lastFinalizedReconDate = _.get(items, "[0].lastFinalizedReconDate");
                    editorOptions.disabled = _.isNil(lastFinalizedReconDate);
                    if(!editorOptions.disabled) {
                        editorOptions.disabledDates = getIsDateDisabledFunction(
                            lastFinalizedReconDate,
                            self.localSecurity.AllowFinalizedReconModifications,
                            self.localSecurity.DepositPreDateDays,
                            self.localSecurity.DepositPostDateDays
                        );
                    }
                }

                let alerts = self.getChangeDateDialogAlerts(items, dateName);

                self.$dialog.promptDateInput({
                    title,
                    label,
                    inputId,
                    width: 535,
                    isRequired,
                    editorOptions,
                    alerts,
                    onOk: async e => {
                        let date = DateTimeHelper.toFormat(e.value, "MMddyyyy") || "mindate";
                        try {
                            await self.saveDateChange(date, dateName, ids);
                            return true;
                        }
                        catch(error) {
                            self.errorMessage = error.message;
                            console.error(error);
                            return true;
                        }
                    }
                });
            },

            getReconChangeDialogAlerts(items) {
                let alerts = [{
                    variant: "info",
                    icon: "fas fa-exclamation-circle",
                    message: "Leave Blank to Unreconcile/Unclear",
                    position: "after"
                }];
                if(_.some(items, i => _.parseNumber(i.depositSlipID, 0) > 0)) {
                    alerts.unshift({
                        variant: "danger",
                        icon: "fas fa-exclamation-triangle",
                        message: "One or more Receipts are on a Deposit Slip cleared on a reconciliation. This action will remove the Deposit Slip and associated Receipts from the reconciliation and could cause the reconciliation to be out of balance.",
                        position: "after"
                    });
                }
                return alerts;
            },

            async showReconciliationChangeDialog(items) {
                const self = this;
                let ids = _.map(items, self.itemKey);
                let now = DateTime.now().startOf("day");
                let targetCheck = _.maxBy(items, item => now.diff(DateTime.fromISO(item.depositDate), "days").days) || items[0];
                let depositID = _.get(targetCheck, self.itemKey);
                let alerts = self.getReconChangeDialogAlerts(items);

                try {
                    let result = await self.fetchLookupData(depositID);
                    let reconciliations = _.map(result.reconciliations, i => new SystemLookupItem(i));
                    self.$dialog.promptSelect({
                        title: "Change Reconciliation Date",
                        label: "Reconciliation",
                        inputId: "cmb_reconciliation_id",
                        isRequired: false,
                        items: reconciliations,
                        valueExpr: "id",
                        displayExpr: "name",
                        alerts,
                        onOk: async e => {
                            let reconciliationID = _.getNumber(e, "selectedValue", 0);
                            await self.saveReconciliation(reconciliationID, ids);
                            return true;
                        }
                    });
                }
                catch(error) {
                    self.errorMessage = error.message;
                }
            },

            showQuickDepositDialog(item) {
                const self = this;
                if (!self.localSecurity.AllowAccessToQuickCheckQuickDepositEntry) return;
                let ordersID = _.get(item, "ordersID") || 0;
                let onOk = (e) => {
                    if(self.readOnly) return true;
                    let form = e.component;
                    return form.save()
                            .then(result => {
                                if(form.hasChanges)
                                    self.$toast.success({ message: `${self.itemTypeName} was saved.` });
                                self.fetchData();
                                return true;
                            })
                            .catch(error => {
                                form.errorMessage = error.errorMessage;
                                console.log(error);
                                return false;
                            })
                            .finally(() => {
                                if (form.refreshOrder) self.$store.dispatch(ORDER_ACTIONS.GET_ORDER, { orderId: ordersID, refresh: true });
                                self.refresh();
                            });
                };
                let onCancel = (e) => {
                    let form = e.component;

                    if (form.hasChanges && !form.isNew) {
                        try {
                            form.validateOnCancel();
                        } catch(error) {
                            form.errorMessage = error.message;
                            return false;
                        }
                        let onDiscard = function (args) {
                            self.refresh();
                            return true;
                        };
                        let onSave = (e) => {
                            return form.save()
                                    .then(result => {
                                        self.$toast.success({ message: `${self.itemTypeName} was saved.` });
                                        self.fetchData();
                                        return true;
                                    })
                                    .catch(error => {
                                        form.errorMessage = error.errorMessage;
                                        console.log(error);
                                        return false;
                                    });
                        };
                        self.$dialog.confirm("Confirm Cancel",
                            `You have pending changes, do you want to save or discard changes?`,
                            onSave,
                            onDiscard,
                            { cancelTitle: 'Discard', okTitle: 'Save'});
                    }
                };
                let title = item.depositID == 0 ? `Add Quick Receipt` : `Edit Receipt: ${_.isNullOrEmpty(item.transactionNumber) ? '(blank)' : item.transactionNumber}`;
                self.$dialog.open({
                    title: title,
                    width: "800",
                    adaptive: true,
                    closeOnEsc: true,
                    component: QuickDepositForm,
                    props: {deposit: item},
                    onOk: onOk,
                    onCancel: onCancel
                });
            },
        }
    }
</script>
