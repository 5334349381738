<template>
    <div id="check-browser" class="content-wrapper escrow-browser">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section title="File Search" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" @keyup.enter="onSearch" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="partnerFilterDisplay != 'All'">
                            <div class="filter-name">Underwriting Partner:</div>
                            <div class="filter-value">{{partnerFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="underwriterFilterDisplay != 'All'">
                            <div class="filter-name">Underwriter:</div>
                            <div class="filter-value">{{underwriterFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="dateFilterDisplay.length > 0">
                            <div class="filter-name">Date:</div>
                            <div class="filter-value">{{dateFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="regionFilterDisplay != 'All'">
                            <div class="filter-name">Region:</div>
                            <div class="filter-value">{{regionFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="branchFilterDisplay != 'All'">
                            <div class="filter-name">Branch:</div>
                            <div class="filter-value">{{gfNo}}</div>
                        </li>
                        <li class="nav-item" v-if="titleCompanyFilterDisplay != 'All'">
                            <div class="filter-name">Title Company:</div>
                            <div class="filter-value">{{titleCompanyFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearSearch">Clear All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_clear"
                                variant="theme"
                                @click="onClearSearch">Clear</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search"
                                variant="theme"
                                :disabled="errorMessage.length > 0 && !hasFilter"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div :class="{ 'col col-6 col-xl-4 form-group form-required': true, 'has-error': v$.request.partnerGUID.$error }">
                    <label for="dtp_underwriting_partner">Underwriting Partner</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_underwriting_partner', id: 'dtp_underwriting_partner' }"
                        value-expr="relationshipKey"
                        display-expr="enterpriseName"
                        :items="partners"
                        v-model="v$.request.partnerGUID.$model"
                        @value-changed="onUnderwritingPartnerChanged"
                        placeholder="Underwriting Partner..."
                        :disabled="readOnly"
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                    <rq-validation-feedback>Underwriting Partner is required.</rq-validation-feedback>
                </div>
                <div :class="{ 'col col-6 col-xl-4 form-group form-required': true, 'has-error': v$.request.underwriterCode.$error }">
                    <label for="dtp_underwriter">Underwriter</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_underwriter', id: 'dtp_underwriter' }"
                        :items="underwriters"
                        value-expr="code"
                        display-expr="displayText"
                        v-model="v$.request.underwriterCode.$model"
                        placeholder="Underwriter..."
                        :disabled="readOnly"
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                    <rq-validation-feedback>Underwriting is required.</rq-validation-feedback>
                </div>
                <div class="col col-6 col-xl-4 form-group form-required">
                    <label for="txt_date_range">Date Range</label>
                    <rq-date-range
                        id="txt_date_range"
                        v-model:start-date="request.dateFrom"
                        v-model:end-date="request.dateTo"
                        :disabled="readOnly"
                        match-value="before-clear"
                        match-field="end"
                        format="MM/dd/yyyy"
                        type="date"
                        start-date-required
                        end-date-required
                        show-calendar-drop-downs
                        show-clear-buttons
                        no-calendars
                        borderless
                    />
                </div>
                <div :class="{ 'col col-6 col-xl-4 form-group form-required': true, 'has-error': v$.request.regionID.$error }">
                    <label for="dtp_region">Region</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_region', id: 'dtp_region' }"
                        :items="regions"
                        value-expr="id"
                        display-expr="name"
                        v-model="v$.request.regionID.$model"
                        placeholder="Region..."
                        :disabled="readOnly"
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <label for="dtp_branch">Branch</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_branch', id: 'dtp_branch' }"
                        :items="branches"
                        value-expr="value"
                        display-expr="name"
                        v-model="request.branchCode"
                        placeholder="Branch..."
                        :disabled="readOnly"
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <label for="dtp_title_company">Title Company</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_title_company', id: 'dtp_title_company' }"
                        :items="titleCompanies"
                        value-expr="value"
                        display-expr="displayText"
                        v-model="request.titleCompanyID"
                        placeholder="Title Company..."
                        :disabled="readOnly"
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
            </div>
        </rq-page-section>
        <rq-tabs
            :tabs="tabItems"
            v-model="tabIndex"
            @activate-tab="onTabActivated"
            >
            <template #files>
                <CmERemitFilesGrid
                    :ref="tabItems[0].ref"
                    :items="items"
                    :relationshipKey="activeRequest.partnerGUID"
                    @refresh-files="onSearch"
                ></CmERemitFilesGrid>
            </template>
            <template #summary>
                <CmERemitFileSummaryGrid
                    :ref="tabItems[1].ref"
                    :items="summaryItems"
                ></CmERemitFileSummaryGrid>
            </template>
            <template #history>
                <div class="row px-4">
                    <div class="col col-6 col-xl-4 form-group form-required">
                        <label for="txt_history_date_range">Date Range</label>
                        <rq-date-range
                            id="txt_history_date_range"
                            v-model:start-date="historyRequest.dateFrom"
                            v-model:end-date="historyRequest.dateTo"
                            :disabled="readOnly"
                            match-value="before-clear"
                            match-field="end"
                            format="MM/dd/yyyy"
                            type="date"
                            show-calendar-drop-downs
                            show-clear-buttons
                            no-calendars
                            borderless
                        />
                    </div>
                    <div class="col col-6 col-xl-4 pt-4">
                        <b-btn
                            automation_id="btn_search_history"
                            variant="theme"
                            @click="onSearchHistory">Search</b-btn>
                    </div>
                </div>
                <CmERemitRequestHistoryGrid
                    :ref="tabItems[0].ref"
                    :items="historyItems"
                ></CmERemitRequestHistoryGrid>
            </template>
        </rq-tabs>
    </div>
</template>

<script>
    import { ERemitFileSearchRequestDto, ERemitFileSummaryDto, ERemitHistorySearchRequestDto }  from "../models";
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import { DateTime } from "luxon";
    import CmERemitFilesGrid from "../components/CmERemitFilesGrid";
    import CmERemitFileSummaryGrid from "../components/CmERemitFileSummaryGrid";
    import CmERemitRequestHistoryGrid from "../components/CmERemitRequestHistoryGrid";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

    export default {
        name: "CmERemit",
        components: { CmERemitFilesGrid, CmERemitFileSummaryGrid, CmERemitRequestHistoryGrid },
        setup: () => ({ v$: useVuelidate() }),
        data () {
            return {
                partners: [],
                regions: [],
                items: [],
                summaryItems: [],
                historyItems: [],
                tabIndex: 0,
                historyRequest: new ERemitHistorySearchRequestDto(),
                request: new ERemitFileSearchRequestDto(),
                activeRequest: new ERemitFileSearchRequestDto(),
                errorMessage: "",
                selectionActions: [],
                filtersExpanded: true,
            };
        },

        created() {
            this.init();
            this.fetchConfigData();
        },

        mounted() {
            this.v$.$reset();
        },

        computed: {
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "IsAdmin",
                    "IsEscrowAdmin",
                    ]);
            },
            tabItems() {
                return [
                    { automation_id: "btn_tab_filesTitle", name: "files", title: this.filesTabName, ref: "filesList" },
                    { automation_id: "btn_tab_summaryTitle", name: "summary", title: "Summary", ref: "summaryList" },
                    { automation_id: "btn_tab_historyTitle", name: "history", title: this.historyTabName, ref: "historyList" },
                ];
            },
            activeTab() { return this.tabItems[this.tabIndex]; },
            hasFilter(){
                return !_.isEqual(new ERemitFileSearchRequestDto(this.request), new ERemitFileSearchRequestDto());
            },
            hasActiveFilter(){
                return !_.isEqual(new ERemitFileSearchRequestDto(this.activeRequest), new ERemitFileSearchRequestDto());
            },
            underwriterFilterDisplay(){
                if (_.size(this.underwriters) == 0) return "None";
                return _.find(this.underwriters, { code: this.activeRequest.underwriterCode })?.name;
            },
            dateFilterDisplay(){
                if (_.isNil(_.get(this, "request.dateFrom")) && _.isNil(_.get(this, "request.dateTo"))) {
                    return "";
                }
                return `${this.getDate(this.request.dateFrom, "Anything")} to ${this.getDate(this.request.dateTo, "Anything")}`;
            },
            titleCompanyFilterDisplay(){
                if (_.size(this.titleCompanies) == 0) return "None";
                return _.find(this.titleCompanies, { value: this.activeRequest.titleCompanyID })?.name || "";
            },
            partnerFilterDisplay(){
                if (_.size(this.partners) == 0) return "None";
                return _.find(this.partners, { relationshipKey: this.activeRequest.partnerGUID })?.enterpriseName;
            },
            regionFilterDisplay(){
                if (_.size(this.regions) == 0) return "None";
                return _.find(this.regions, { id: this.activeRequest.regionID })?.name || "";
            },
            filesTabName() {
                return (_.size(this.items) == 0) ? "Files" :`Files (${_.size(this.items)})`;
            },
            historyTabName() {
                return (_.size(this.historyItems) == 0) ? "History" :`History (${_.size(this.historyItems)})`;
            },
            readOnly() { return false; },
            hasFilterChanged(){
                return !_.isEqual(new ERemitFileSearchRequestDto(this.activeRequest), new ERemitFileSearchRequestDto(this.request));
            },
            branches() {
                if (_.size(this.regions) == 0 || _.isNullOrEmpty(this.request.regionID)) return [];
                let region = _.find(this.regions, ["id", this.request.regionID]);
                return _.sortBy(region?.branches, "name") || [];
            },
            titleCompanies() {
                if (_.size(this.regions) == 0 || _.isNullOrEmpty(this.request.regionID)) return [];
                let region = _.find(this.regions, ["id", this.request.regionID]);
                return region?.titleCompanies || [];
            },
            underwriters() {
                if (_.size(this.partners) == 0 || _.isNullOrEmpty(this.request.partnerGUID)) return [];
                let partner = _.find(this.partners, ["relationshipKey", this.request.partnerGUID]);
                return partner?.underwriters || [];
            }
        },

        validations() {
            var result = {
                request: {
                    partnerGUID: {
                        required,
                    },
                    underwriterCode: {
                        required,
                    },
                    regionID: {
                        required,
                    },
                }
            }
            return result;
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            fetchData() {
                const self = this;
                self.errorMessage = "";
                let apiPromise = self.$api.CmERemitApi.searchFiles(self.request);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.setData(result);
                        self.activeRequest = new ERemitFileSearchRequestDto(self.request);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading eRemit Files.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            fetchHistoryData() {
                const self = this;
                self.errorMessage = "";
                let apiPromise = self.$api.CmERemitApi.searchRequests(self.historyRequest);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.historyItems = result;
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading eRemit Files.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            fetchConfigData() {
                const self = this;
                let apiPromise = self.$api.CmERemitApi.getConfigurationData();
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.partners =  _.sortBy(result.partners, "enterpriseName");
                        self.regions =  _.sortBy(result.regions, "name");
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Escrow Users.` });
                    });
            },

            getDate(dateStr, nullValue = null) {
                return _.isNullOrEmpty(dateStr) ? nullValue : dateStr;
            },

            init() {
                this.tabs = { Files: 0, Summary: 1 };
                let dateTo = DateTime.now().toFormat("MM/dd/yyyy");
                let dateFrom = DateTime.now().minus({days: 30}).toFormat("MM/dd/yyyy");
                this.historyRequest = new ERemitHistorySearchRequestDto({dateTo, dateFrom});
                this.request = new ERemitFileSearchRequestDto({dateTo, dateFrom});
            },

            onClearSearch() {
                this.filtersExpanded = true;
                this.request = new ERemitFileSearchRequestDto();
                this.activeRequest = new ERemitFileSearchRequestDto();
                this.items = [];
                this.summaryItems = [];
                this.refresh("filesList");
                this.refresh("summaryList");
            },

            onUnderwritingPartnerChanged(e) {
                if (!_.isNullOrEmpty(e.value)) {
                    let u = _.find(this.partners, { relationshipKey: e.value });
                    let end_dt = u.defaultEndDate || DateTime.now().startOf("day").toISO();
                    let start_dt = u.defaultStartDate || DateTime.fromISO(end_dt).minus({days: 60}).startOf("day").toISO();
                    this.request.dateFrom = DateTimeHelper.toFormat(start_dt);
                    this.request.dateTo = DateTimeHelper.toFormat(end_dt);
                    if (_.size(u.underwriters) == 1) {
                        this.request.underwriterCode = u.underwriters[0].code;
                    }
                } else {
                    this.request.dateFrom = null;
                    this.request.dateTo = null;
                    this.request.underwriterCode = null;
                }
            },

            onSearch() {
                this.v$.$touch();
                this.errorMessage = "";
                if(!this.hasFilter) {
                    this.errorMessage = "Please enter at least one value before searching.";
                    return;
                }
                this.fetchData();
            },

            onSearchHistory() {
                this.fetchHistoryData();
            },

            onTabActivated(e) {
                this.refresh(e.tab.ref);
            },

            refresh(name) {
                let grid = _.get(this.$refs, name);
                let hasRefresh = _.isFunction(_.get(grid, 'refresh'));
                if (grid && hasRefresh) {
                    grid.refresh();
                }
            },

            setData(data) {
                const self = this;
                self.items = data;
                let allPolicies = _.flatMap(data, d => d.policies);
                let ownerPremiums = [];
                let ownerEndorsements = [];
                let mortgagePremiums = [];
                let mortgageEndorsements = [];
                let summaryItems = [];
                _.each(allPolicies, policy => {
                    if (_.get(policy, "policyType") == "ownerPolicy") {
                        ownerPremiums.push(...policy.premiums);
                        ownerEndorsements.push(...policy.endorsements);
                    }
                    if (_.get(policy, "policyType") == "mortgagePolicy") {
                        mortgagePremiums.push(...policy.premiums);
                        mortgageEndorsements.push(...policy.endorsements);
                    }
                });
                if (_.size(ownerPremiums) > 0) {
                    summaryItems.push(self.createERemitFileSummaryDto("Owner's Policy", ownerPremiums));
                }
                if (_.size(ownerEndorsements) > 0) {
                    summaryItems.push(self.createERemitFileSummaryDto("Owner's Endorsement", ownerEndorsements));
                }
                if (_.size(mortgagePremiums) > 0) {
                    summaryItems.push(self.createERemitFileSummaryDto("Mortgage Policy", mortgagePremiums));
                }
                if (_.size(mortgageEndorsements) > 0) {
                    summaryItems.push(self.createERemitFileSummaryDto("Mortgage Endorsement", mortgageEndorsements));
                }
                self.summaryItems = summaryItems;
            },

            createERemitFileSummaryDto(type, items) {
                let typeCount = _.size(items);
                let liabilityTotal = _.sumBy(items, "liability");
                let grossTotal = _.sumBy(items, "grossAmount");
                let underwriterSplitTotal = _.sumBy(items, "underwriterSplitAmount");
                let agentSplitTotal = _.sumBy(items, "underwriterSplitAmount");
                return new ERemitFileSummaryDto({type, typeCount, liabilityTotal, grossTotal, underwriterSplitTotal, agentSplitTotal });
            }
        }
    }
</script>
