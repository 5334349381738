<template>
    <div class="rq-container content-wrapper">
        <rq-banner
            ref="errorBanner"
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="errorMessage.length > 0"
        />
        <div class="row">
            <div class="col col-4 form-group">
                <file-number-input
                    automation_id="txt_gfno"
                    ref="fileNumber"
                    label="File #"
                    v-model:order-id="item.ordersID"
                    v-model:has-error="fileNumberInvalid"
                    :disabled="readOnly || hasReconciliationID || item.isTransfer"
                    v-model="item.gfNo"
                    :show-clear-button="!hasReconciliationID"
                    required
                    show-search-button
                />
            </div>
            <div :class="{ 'col col-4 form-group':true, 'form-required':hasCheckNumber || hasWireNumber, 'has-error':v$.item.checkDate.$error }">
                <label for="dtp_check_date">Date</label>
                <div v-rq-tooltip.bottom.hover :title="disableDateTooltip">
                    <rqdx-date-box
                        id="dtp_check_date"
                        :disabled="readOnly || hasReconciliationID || !hasOrdersID || disableDates"
                        :disabled-dates="getDisabledCheckDates"
                        @valueChanged="onCheckDateChanged"
                        v-model="v$.item.checkDate.$model"
                    />
                    <rq-validation-feedback
                        :messages="{
                            'Check Date is required': v$.item.checkDate.required.$invalid,
                            'Check Date is not within range': !v$.item.checkDate.required.$invalid && v$.item.checkDate.isInRange.$invalid,
                        }"
                    />
                </div>
            </div>
            <div class="col col-4" v-if="item.isWire">
                <rq-action-form-group
                    label="Wire #"
                    label-for="txt_wire_number"
                    :required="!systemDefaults.useAutoGeneratedWireNumbers && systemDefaults.wireOutWireNumberRequired && item.isWire"
                    :has-error="v$.item.wireNumber.$invalid">
                    <template #default>
                        <div class="input-group">
                            <span class="input-group-text">{{prePendLetter}}-</span>
                            <input
                                automation_id="txt_wire_number"
                                v-model="item.wireNumber"
                                :disabled="hasReconciliationID || !hasOrdersID || systemDefaults.useAutoGeneratedWireNumbers"
                                type="text"
                                class="form-control"
                                placeholder="Wire #"
                                maxlength="15">
                                <rq-validation-feedback
                                    :messages="{
                                        'Wire number already in use': v$.item.wireNumber.isNotInUse.$invalid,
                                        'Wire number is required': v$.item.wireNumber.required.$invalid
                                    }"
                                />
                        </div>
                    </template>
                    <template #below>
                        <b-form-checkbox
                            automation_id="chk_isWire"
                            id="isWire"
                            v-model="item.isWire"
                            @change="onIsWireChanged"
                            :disabled="hasReconciliationID || !hasOrdersID">
                            Is Wire?
                        </b-form-checkbox>
                    </template>
                </rq-action-form-group>
            </div>
            <div class="col col-4" v-else-if="!item.isTransfer">
                <rq-action-form-group
                    label="Check #"
                    label-for="txt_check_number"
                    :required="hasCheckDate"
                    :has-error="v$.item.checkNumber.$invalid">
                    <template #default>
                        <rq-input-number
                            automation_id="txt_check_number"
                            formatType="basic"
                            defaultValue=""
                            decimals="0"
                            :commas="false"
                            :minValue="0"
                            :allowNull="true"
                            :disabled="hasReconciliationID || !hasOrdersID || (!localSecurity.AllowCheckNumberChange && !isNew)"
                            input-group
                            no-prefix
                            v-model="item.checkNumber"
                            value-event="input"
                        />
                        <rq-validation-feedback
                            :messages="{
                                'Check# already in use': v$.item.checkNumber.isNotInUse.$invalid,
                                'Check# is out of allowable range': v$.item.checkNumber.inRange.$invalid,
                                'Check# is required': v$.item.checkNumber.required.$invalid
                            }"
                        />
                    </template>
                    <template #below>
                        <b-form-checkbox
                            automation_id="chk_isWire"
                            id="isWire"
                            v-model="item.isWire"
                            @change="onIsWireChanged"
                            :disabled="readOnly || hasReconciliationID || !hasOrdersID || !localSecurity.AllowChangeCheckToWire">
                            Is Wire?
                        </b-form-checkbox>
                    </template>
                </rq-action-form-group>
            </div>
        </div>
        <div class="row mt-n2">
            <div :class="{ 'col col-8 form-group form-required':true, 'has-error':v$.item.payee.$error }">
                <label for="txt_payee">Pay to the order of</label>
                <div class="input-group">
                    <input automation_id="txt_payee"
                        class="form-control"
                        type="text"
                        v-model="v$.item.payee.$model"
                        placeholder="Select Payee Type ->"
                        maxlength="100"
                        readonly
                        :disabled="hasReconciliationID || !hasOrdersID || readOnly"
                    >
                    <rq-validation-feedback :offset="30">Payee is required</rq-validation-feedback>
                    <b-dropdown :disabled="hasReconciliationID || !hasOrdersID || readOnly || item.isTransfer">
                        <b-dropdown-item @click="onChangePayeeToFileContact">File Contact</b-dropdown-item>
                        <b-dropdown-item @click="onChangePayeeToCompany">Company</b-dropdown-item>
                        <b-dropdown-item @click="onChangePayeeToManual">Manual</b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <div class="col col-4">
                <rq-action-form-group
                    label="Amount"
                    label-for="txt_amount"
                    :show-action="useBankAmount"
                    :has-error="v$.item.amount.$error"
                    action-class="overridden"
                    action-automation-id="btn_txt_amount_use_bank"
                    action-label="Use Bank Amount"
                    @action-click="onUseBankAmount"
                    required>
                    <template #default>
                        <rq-input-number
                            automation_id="txt_amount"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            :disabled="hasReconciliationID || !hasOrdersID || item.isTransfer"
                            decimals="2"
                            input-group
                            no-prefix
                            prependIcon="fas fa-dollar-sign"
                            v-model="v$.item.amount.$model"
                        />
                        <rq-validation-feedback
                            :messages="{
                                'Amount is required': v$.item.amount.required.$invalid,
                                'Amount must be greater than Zero': v$.item.amount.isGreaterThanZero.$invalid,
                            }"
                        />
                    </template>
                </rq-action-form-group>
            </div>
        </div>
        <div class="row mt-n2">
            <div class="col col-4 form-group">
                <label for="dtp_void_date">Void Date</label>
                <div v-rq-tooltip.bottom.hover :title="disableDateTooltip">
                    <rqdx-date-box
                        id="dtp_void_date"
                        :disabled="hasStopPayDate || hasReconciliationID || !hasOrdersID || item.isTransfer || item.isEFER || disableDates || disableVoidDate"
                        :disabled-dates="getDisabledVoidDates"
                        @valueChanged="onVoidDateChanged"
                        v-model="item.voidDate"
                    />
                </div>
            </div>
            <div class="col col-4 form-group">
                <label for="dtp_stop_pay_date">Stop Pay Date</label>
                <div v-rq-tooltip.bottom.hover :title="disableDateTooltip">
                    <rqdx-date-box
                        id="dtp_stop_pay_date"
                        :disabled="hasVoidDate || hasReconciliationID || !hasOrdersID || item.isTransfer || item.isEFER || disableDates || disableStopPayDate"
                        :disabled-dates="getDisabledVoidDates"
                        @valueChanged="onVoidDateChanged"
                        v-model="item.stopPayDate"
                    />
                </div>
            </div>
            <div class="col col-4 form-group">
                <label for="cmb_reconciliation_id">Reconciliation</label>
                <div v-rq-tooltip.bottom.hover :title="disableDateTooltip">
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_reconciliationID', id: 'drp_reconciliationID' }"
                        :items="reconciliations"
                        value-expr="id"
                        display-expr="name"
                        :disabled="disableDates || disableReconciliation || !hasOrdersID || isNew"
                        v-model="item.reconciliationID"
                        @valueChanged="onReconcilliationDateChanged"
                        :wrap-item-text="true"
                        :show-clear-button="true"
                        :search-enabled="true"
                    />
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col col-4 form-group">
                <label for="txt_fed_reference_number">Fed Reference No.</label>
                <input
                    automation_id="txt_fed_reference_number"
                    class="form-control"
                    type="text"
                    disabled
                    v-model="item.fedRefNumber"
                    />
            </div>
            <div v-if="!isNew" class="col col-8">

                <rq-action-form-group
                    label="Change Reason"
                    label-for="txt_change_reason"
                    :required="systemDefaults.requireChangeReasonForReceiptsAndDisbursements"
                    :has-error="v$.changeReason.$invalid">
                    <template #default>
                        <input
                            automation_id="txt_change_reason"
                            v-model="changeReason"
                            :disabled="!hasChanges"
                            type="text"
                            class="form-control"
                            maxlength="250">
                            <rq-validation-feedback
                                :messages="{
                                    'Change Reason is required': v$.changeReason.required.$invalid
                                }"
                            />
                    </template>
                </rq-action-form-group>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { DateTime } from "luxon";
    import { SystemLookupItem } from "@/shared/models/models";
    import { CheckStatus } from '../../file/check-writing/enums';
    import FileContactSelection from "@file-shared/components/FileContactSelection";
    import CheckWritingManualPayee from "../../file/check-writing/components/CheckWritingManualPayee";
    import CompanyContactLookup from "@order-entry/contacts/components/CompanyContactLookup";
    import FileNumberInput from "@/shared/components/rq/FileNumberInput";
    import { required, requiredIf } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import { CheckPrintDataDto } from "../../file/check-writing/models";
    const FINALIZED_RECON_WARNING = "Item is dated within a finalized reconciliation time period and will recalculate affected finalized reconciliations.";

    export default {
        name: "QuickCheckForm",
        props: {
            check: {type: Object, required: true},
            bankAmount: {type: Number, required: false}
        },
        components: { FileNumberInput },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                errorMessage: "",
                original: {},
                item: {},
                reconciliations: [],
                banks: [],
                fileNumberInvalid: false,
                printData: new CheckPrintDataDto(),
                checkNumberInUse: false,
                wireNumberInUse: false,
                changeReason: '',
                originalChangeReason: ''
            }
        },
        watch: {
            "item.ordersID":{
                handler: function(newValue, oldValue) {
                    if (_.isEqual(newValue, oldValue) || _.isUndefined(oldValue)) return;
                    if (_.parseNumber(newValue, 0) !== _.parseNumber(oldValue, 0)) {
                        this.fetchLookupData(newValue, "order");
                        if (this.warnFinalRecon) {
                            this.errorMessage = FINALIZED_RECON_WARNING;
                        }
                    }
                    this.getCheckNumberData();
                }
            },
            "item.checkNumber":{
                handler: function(newValue, oldValue){
                    if (_.isEqual(newValue, oldValue)) return;
                    if (_.isNil(newValue)) return;
                    this.isCheckNumberInUse();
                }
            },
            "item.wireNumber":{
                handler: function(newValue, oldValue){
                    if (_.isEqual(newValue, oldValue)) return;
                    if (_.isNil(newValue)) return;
                    this.isWireNumberInUse();
                }
            },
            "item.amount":{
                handler: function(newValue, oldValue) {
                    if (_.isEqual(newValue, oldValue) || _.isUndefined(oldValue)) return;
                    if (!_.isEqual(newValue, this.original.amount) && this.warnFinalRecon) {
                        this.errorMessage = FINALIZED_RECON_WARNING;
                    } else {
                        this.errorMessage = "";
                    }
                }
            },
            "hasChanges": {
                handler: function(newValue, oldValue) {
                    if(newValue == false) {
                        this.changeReason = this.originalChangeReason;
                    }
                    else {
                        this.changeReason = '';
                    }
                }
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                systemDefaults: state => state.system.systemDefaults,
                user: state => state.authentication.session.user
            }),
            prePendLetter() {
                if (_.parseBool(this.item.isWire, false)) return "W";
                if (_.parseBool(this.item.isEFER, false)) return "EFER";
                return "";
            },
            warnFinalRecon() {
                return (!this.isNew && !_.isNil(this.original.checkDate) && this.localSecurity.AllowFinalizedReconModifications && DateTime.fromISO(this.original.checkDate).diff(DateTime.fromISO(this.original.lastFinalizedReconDate), "days").days <= 0)
                    || (this.isNew && !_.isNil(this.item.checkDate) && this.localSecurity.AllowFinalizedReconModifications && DateTime.fromISO(this.item.checkDate).diff(DateTime.fromISO(this.item.lastFinalizedReconDate), "days").days <= 0);
            },
            disableDates() { return !this.isNew && this.isCleared && !this.item.isTransfer && !_.isNil(this.item.checkDate) && !this.localSecurity.AllowFinalizedReconModifications && DateTime.fromISO(this.item.checkDate).diff(DateTime.fromISO(this.item.lastFinalizedReconDate), "days").days <= 0; },
            disableDateTooltip() { return this.disableDates ? "Access Restricted" : ""; },
            disableReconciliation() { return _.parseBool(this.item.isTransfer, true) || _.parseBool(this.item.isEFER, true) || this.item.checkStatus == CheckStatus.Void || this.item.checkStatus == CheckStatus.StopPay || this.item.checkStatus == CheckStatus.None || !_.isNil(this.item.voidDate) || !_.isNil(this.item.stopPayDate); },
            numberLabel() { return this.item.isWire ? 'Wire' : 'Check'; },
            hasChanges() { return _.size(this.getAuditChanges(this.original, this.item, ["gfNo"])) > 0; },
            hasReconciliationID() { return _.getNumber(this, 'item.reconciliationID', 0) > 0 || !_.isNil(this.item.reconciliationDate); },
            hadReconciliationID() { return _.getNumber(this, 'original.reconciliationID', 0) > 0 || !_.isNil(this.original.reconciliationDate); },
            hasPayee() { return _.getNumber(this, 'item.payeeCompanyID', 0) > 0 || _.getNumber(this, 'item.buyerSellerID', 0) || (_.size(this.item.payee) + _.size(this.item.payeeZip) + _.size(this.item.payeeCity) + _.size(this.item.payeeState) + _.size(this.item.payeeZip)) > 0; },
            hasOrdersID() { return _.getNumber(this, 'item.ordersID', 0) > 0; },
            hasVoidDate() { return (!_.isNil(this.original.voidDate) && !this.isNew) || (!_.isNil(this.item.voidDate) && this.isNew); },
            hasStopPayDate() { return (!_.isNil(this.original.stopPayDate) && !this.isNew) || (!_.isNil(this.item.stopPayDate) && this.isNew); },
            hasCheckDate() { return !_.isNil(this.item.checkDate); },
            hasCheckNumber() { return !_.isNil(this.item.checkNumber) && this.item.checkNumber !== '' && this.item.checkNumber !== -1; },
            hasWireNumber() { return !_.isNil(this.item.wireNumber) && this.item.wireNumber !== ''; },
            hasFormError() {return this.v$.$invalid || this.errorMessage.length >  0 || this.v$.error},
            localSecurity(){
                return this.securitySettings.findValues([
                    "IsAdmin",
                    "IsEscrowAdmin",
                    "AllowChangeCheckToWire",
                    "AllowCheckNumberChange",
                    "AllowEscrowAdminBrowserChange",
                    "AllowFinalizedReconModifications",
                    "CheckPreDateDays",
                    "CheckPostDateDays",
                    "VoidCheckDateDays"
                    ]);
            },
            isCleared() { return _.getNumber(this, 'item.checkStatus', 0) === CheckStatus.Cleared; },
            isNew() { return _.getNumber(this, 'item.checksID', 0) == 0; },
            readOnly() { return !this.localSecurity.AllowEscrowAdminBrowserChange; },
            restrictCheckDateToRecon() { return !this.isNew && !this.isCleared && this.localSecurity.AllowEscrowAdminBrowserChange && !this.localSecurity.AllowFinalizedReconModifications && this.localSecurity.CheckPreDateDays === -1 && this.localSecurity.CheckPostDateDays === -1; },
            restrictVoidDateToRecon() { return !this.isNew && !this.isCleared && this.localSecurity.AllowEscrowAdminBrowserChange && !this.localSecurity.AllowFinalizedReconModifications && this.localSecurity.VoidCheckDateDays === -1; },
            useBankAmount() { return _.getNumber(this, 'bankAmount', 0) > 0 && !_.isEqual(_.getNumber(this, 'bankAmount', 0), _.getNumber(this, 'item.amount', 0)); },
            checkNumberInRange(){
                if(!this.hasOrdersID || !this.hasCheckDate) return true;
                if(this.item.isWire) return true;//if wire, always true
                let minNumber = _.getNumber(this, 'printData.rangeStart', 0);
                let maxNumber = _.getNumber(this, 'printData.rangeEnd', 0);

                if(maxNumber === 0 && minNumber === 0) return true;

                let checkNumber = _.getNumber(this, 'item.checkNumber', 0);
                return checkNumber <= maxNumber && checkNumber >= minNumber;
            },
            disableVoidDate() {return !_.isEmpty(this.item.stopPayDate) || this.item.checkStatus === CheckStatus.None;},
            disableStopPayDate(){return !_.isEmpty( this.item.voidDate) || this.item.checkStatus === CheckStatus.None;}
        },
        created(){
            this.original = _.clone(this.check);
            this.item = _.clone(this.check);
            if (!this.isNew) {
                this.fetchLookupData(this.item.checksID, "check");
            }
        },
        validations() {
            const self = this;

            var result = {
                item: {
                    payee: {
                        hasPayee(val) {
                            return self.hasPayee;
                        }
                    },
                    amount: {
                        required,
                        isGreaterThanZero(val) {
                            return _.gt(val, 0);
                        }
                    },
                    checkNumber: {
                        // required,
                        required: requiredIf(function() {
                            if (self.item.isTransfer) return false;
                            if (self.item.isWire) return true;
                            // if (self.hasCheckDate) return self.hasCheckNumber;
                            return self.hasCheckDate;
                        }),
                        // required(val) {
                        //     if (self.item.isWire) return true;
                        //     if (self.hasCheckDate) return self.hasCheckNumber;
                        //     return true;
                        // },
                        inRange(val) {
                            return self.checkNumberInRange;
                        },
                        isNotInUse(val) {
                            if (self.item.isWire) return true;
                            if (!self.hasCheckNumber) return true;
                            return !self.checkNumberInUse;
                        }
                    },
                    wireNumber: {
                        required: requiredIf(function(){
                            return self.item.isWire && !self.systemDefaults.useAutoGeneratedWireNumbers && self.systemDefaults.wireOutWireNumberRequired;
                        }),
                        isNotInUse(val) {
                            return !self.item.isWire || !self.wireNumberInUse || self.systemDefaults.useAutoGeneratedWireNumbers;
                        }
                    },
                    checkDate: {
                        required: requiredIf(function() {
                            if (self.item.isWire && self.hasWireNumber) return self.hasWireNumber;
                            // if (self.hasCheckNumber) return self.hasCheckDate;
                            return self.hasCheckNumber;
                        }),
                        // required(val) {
                        //     if (self.item.isWire && self.hasWireNumber) return self.hasCheckDate;
                        //     if (self.hasCheckNumber) return self.hasCheckDate;
                        //     return true;
                        // },
                        isInRange(val) {
                            return !self.isCheckDateInRange(val);
                        }
                    },
                },
                changeReason: {
                    required: requiredIf(function(){
                            return !self.isNew && 
                            self.hasChanges &&
                            self.systemDefaults.requireChangeReasonForReceiptsAndDisbursements;
                        })
                }
            }
            return result;
        },
        methods: {
            clearOrderInfo() {
                this.item.ordersID = null;
                this.item.gfNo = null;
            },

            fetchLookupData(id, type) {
                const self = this;
                let apiPromise = self.$api.EscrowAccountingApi.getLookups(id, type);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.errorMessage = "";
                        if (self.restrictCheckDateToRecon) {
                            self.reconciliations = _.map(_.filter(result.reconciliations, ["inactive", false]), i => new SystemLookupItem(i));
                        } else {
                            self.reconciliations = _.map(result.reconciliations, i => new SystemLookupItem(i));
                        }
                        if (type === "order") {
                            self.banks = _.map(result.banks, i => new SystemLookupItem(i));
                            self.item.bankCompanyID = _.get(self, "banks[0].id") || null;
                            let dateStr = _.get(result, "lastFinalizedReconDate[0].name") || '01/01/2000';
                            self.item.lastFinalizedReconDate = DateTime.fromFormat(dateStr, "MM/dd/yyyy").toISO();
                            if (!self.isNew) {
                                if (!_.isEqual(self.item.bankCompanyID, self.original.bankCompanyID)) {
                                    self.errorMessage = `This File# does not use the same Escrow Account as ${self.original.gfNo}`;
                                }
                            }
                            if (_.isNil(self.item.bankCompanyID)) {
                                self.errorMessage = "No Escrow Account associated with this File#";
                            }
                        }
                        if (self.errorMessage.length > 0) self.$emit("disable-ok");
                        else self.$emit("enable-ok");
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Check Lookups.` });
                        //self.$emit("disable-ok");
                    });
                
                let escrowActivityApiPromise = self.$api.EscrowAccountingApi.getEscrowActivity({
                    checksID : self.item.checksID
                });
                self.$rqBusy.wait(escrowActivityApiPromise)
                    .then(result => {
                        if(result && result.checks && result.checks.length > 0) {
                            var last = result.checks.at(-1);
                            self.changeReason = last.changeReason;
                            self.originalChangeReason = last.changeReason;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });                
            },

            getCheckNumberData(){
                const self = this;
                if(!self.item || !self.item.ordersID) return;

                let apiPromise = self.$api.CheckWritingApi.getCheckPrintData(self.item.ordersID, self.item.bankCompanyID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.printData = new CheckPrintDataDto(result);
                        if(_.isNil(self.item.checkNumber)) {
                            self.item.checkNumber = self.printData.nextCheckNumber;
                            self.original.checkNumber = self.printData.nextCheckNumber;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading new Check Number.` });
                    });

            },

            isCheckNumberInUse:_.debounce(function(){
                const self = this;
                //if the check number has not been changed from the original, always mark it as not in use
                if(_.isEqual(self.item.checkNumber, self.original.checkNumber)){
                    self.checkNumberInUse = false;
                    return;
                }

                //otherwise validate it's available in the api
                let promise = self.$api.CheckWritingApi.checkNumberInUse(self.item.ordersID, self.item.checkNumber);
                this.$rqBusy.wait(promise).then((result)=>{
                    self.checkNumberInUse = result;
                });
                }, 350),

            isWireNumberInUse: _.debounce(function() {
                const self = this;

                //if the wire number has not been changed from the original or is auto generated, always mark it as not in use
                if(_.isEqual(self.item.wireNumber, self.original.wireNumber) || self.systemDefaults.useAutoGeneratedWireNumbers) {
                    self.wireNumberInUse = false;
                    return;
                }

                let wireNumber = self.item.wireNumber.length == 0 ? null : self.item.wireNumber;
                //otherwise validate it's available in the api
                let promise = self.$api.CheckWritingApi.wireNumberInUse(self.item.bankCompanyID, wireNumber);
                this.$rqBusy.wait(promise).then((result)=>{
                    self.wireNumberInUse = result;
                });

            }, 350),

            getDisabledCheckDates(e) {
                //note: returning true disables the date, false enables it.  Can be confusing.
                let dt = _.parseLuxonDateTime(e.date);
                let currentDate = dt.startOf("day");
                if (this.restrictCheckDateToRecon) {
                    //if they are allowed to make changes to a check, but not a finalized recon, then restrict the days to the dates within the range of said recon
                    let reconciliationStartDate = DateTime.fromISO(this.item.reconciliationStartDate).startOf("day");
                    let reconciliationEndDate = DateTime.fromISO(this.item.reconciliationEndDate).startOf("day");
                    return reconciliationStartDate.diff(currentDate, "days").days > 0 || reconciliationEndDate.diff(currentDate, "days").days < 0;
                }
                let now = DateTime.now().startOf("day");
                let lastFinalizedReconDate = DateTime.fromISO(this.item.lastFinalizedReconDate).plus({ days: 1}).startOf("day");
                let reconDaysDiff = lastFinalizedReconDate.diff(now, "days").days;
                let checkPreDateDaysSetting = this.localSecurity.AllowFinalizedReconModifications ? this.localSecurity.CheckPreDateDays : Math.abs(reconDaysDiff);
                let checkPostDateDaysSetting = this.localSecurity.CheckPostDateDays;

                //-1 === unlimited
                if(checkPreDateDaysSetting === -1 && checkPostDateDaysSetting === -1) return false;

                let daysDiff = currentDate.diff(now, "days").days;

                //if lastFinalizedReconDate is a future date and they are not allowed to modify finalized recons, use lastFinalizedRecon instead of currentDate
                if(reconDaysDiff > 0 && !this.localSecurity.AllowFinalizedReconModifications){
                    checkPreDateDaysSetting = 0 - reconDaysDiff;
                    daysDiff = currentDate.diff(lastFinalizedReconDate, "days").days;
                }

                if(this.isNew && daysDiff === 0) return false; //today is always valid, for new

                //predate is unlimited but not postdate
                if(checkPreDateDaysSetting === -1){
                    if(daysDiff <= 0) return false;
                    return daysDiff > checkPostDateDaysSetting;
                }
                //postdate is unlimited but not predate
                else if(checkPostDateDaysSetting === -1){
                    if(daysDiff >= 0) return false;
                    return Math.abs(daysDiff) > checkPreDateDaysSetting;
                }
                //neither is unlimited
                return (daysDiff < 0 && Math.abs(daysDiff) > checkPreDateDaysSetting) || (daysDiff > 0 && daysDiff > checkPostDateDaysSetting);
            },

            getDisabledVoidDates(e) {
                //note: returning true disables the date, false enables it.  Can be confusing.
                let currentDate = DateTime.fromJSDate(e.date).startOf("day");
                if (this.restrictVoidDateToRecon) {
                    //if they are allowed to make changes to a check, but not a finalized recon, then restrict the days to the dates within the range of said recon
                    let reconciliationStartDate = DateTime.fromISO(this.item.reconciliationStartDate).startOf("day");
                    let reconciliationEndDate = DateTime.fromISO(this.item.reconciliationEndDate).startOf("day");
                    return reconciliationStartDate.diff(currentDate, "days").days > 0 || reconciliationEndDate.diff(currentDate, "days").days < 0;
                }
                let now = DateTime.now().startOf("day");
                let lastFinalizedReconDate = DateTime.fromISO(this.item.lastFinalizedReconDate).plus({ days: 1}).startOf("day");
                let reconDaysDiff = lastFinalizedReconDate.diff(now, "days").days;
                let voidPreDateDaysSetting = this.localSecurity.AllowFinalizedReconModifications ? this.localSecurity.VoidCheckDateDays : Math.abs(reconDaysDiff);
                let voidPostDateDaysSetting = this.localSecurity.VoidCheckDateDays;

                //-1 === unlimited
                if(voidPreDateDaysSetting === -1 && voidPostDateDaysSetting === -1) return false;

                let daysDiff = currentDate.diff(now, "days").days;

                if(this.isNew && daysDiff === 0) return false; //today is always valid, for new

                //predate is unlimited but not postdate
                if(voidPreDateDaysSetting === -1){
                    if(daysDiff <= 0) return false;
                    return daysDiff > voidPostDateDaysSetting;
                }
                //postdate is unlimited but not predate
                else if(voidPostDateDaysSetting === -1){
                    if(daysDiff >= 0) return false;
                    return Math.abs(daysDiff) > voidPreDateDaysSetting;
                }
                //neither is unlimited
                return (daysDiff < 0 && Math.abs(daysDiff) > voidPreDateDaysSetting) || (daysDiff > 0 && daysDiff > voidPostDateDaysSetting);
            },

            isCheckDateInRange(val){
                const self = this;
                if (_.isNil(val)) return false;
                return self.getDisabledCheckDates({date: val});
            },

            onCheckDateChanged(e) {
                const self = this;
                // self.errorMessage = "";
                self.item.checkDate = e.value;
                self.v$.item.checkDate.$touch();
                //if they set to back to the original, just bail
                if (_.isEqual(e.value, self.original.checkDate)) return;
                //if they are allowed to alter finalized recons, and happen to back date an item that falls inside the thru date of a recon, then warn
                if (self.localSecurity.AllowFinalizedReconModifications && !_.isNil(e.value) && DateTime.fromISO(e.value).diff(DateTime.fromISO(self.item.lastFinalizedReconDate), "days").days <= 0) {
                    self.errorMessage = FINALIZED_RECON_WARNING;
                }
                //if they are allowed to alter finalized recons, and happen to post date an item that falls inside the thru date of a recon, then warn
                if (self.localSecurity.AllowFinalizedReconModifications && !_.isNil(self.original.checkDate) && DateTime.fromISO(self.original.checkDate).diff(DateTime.fromISO(self.item.lastFinalizedReconDate), "days").days <= 0 && DateTime.fromISO(e.value).diff(DateTime.fromISO(self.item.lastFinalizedReconDate), "days").days > 0) {
                    self.errorMessage = FINALIZED_RECON_WARNING;
                }
            },

            onReconcilliationDateChanged(e) {
                const self = this;
                self.errorMessage = "";
                self.v$.item.checkDate.$touch();
                //if they are allowed to alter finalized recons, and happen to (re)assign, then warn
                if (self.warnFinalRecon && !_.isEqual(e.value, self.original.reconciliationID)) {
                    self.errorMessage = FINALIZED_RECON_WARNING;
                }
            },

            onUseBankAmount(){
                this.item.amount = this.bankAmount;
            },

            onVoidDateChanged(e) {
                const self = this;
                self.errorMessage = "";
                if (self.warnFinalRecon && !_.isNil(e.value)) {
                    self.errorMessage = FINALIZED_RECON_WARNING;
                }
            },

            onIsWireChanged(e) {
                const self = this;
                self.item.isWire = e;
                if (e) {
                    if (self.systemDefaults.useAutoGeneratedWireNumbers) {
                        let promise = self.$api.CheckWritingApi.generateWireNumber(self.item.bankCompanyID, self.item.checksID);
                        self.$rqBusy.wait(promise).then((result) => {
                            self.item.wireNumber = result;
                        });
                    } else {
                        self.item.wireNumber = self.original.wireNumber;
                    }
                    self.item.checkNumber = -1;
                    self.item.checkStatus = CheckStatus.Wire;
                } else {
                    self.item.wireNumber = null
                    self.item.checkNumber = null;
                    self.item.checkDate = null;
                    self.item.checkStatus = CheckStatus.None;// The backend will change to the appropriate status based off other data, etc
                }
            },

            onChangePayeeToFileContact(e) {
                if(this.hasReconciliationID || !this.hasOrdersID || this.readOnly) return;
                this.showFileContactsDialog(this.item);
            },

            onChangePayeeToCompany(e) {
                if(this.hasReconciliationID || !this.hasOrdersID || this.readOnly) return;
                this.showCompanyDialog(this.item);
            },

            onChangePayeeToManual(e) {
                if(this.hasReconciliationID || !this.hasOrdersID || this.readOnly) return;
                this.showManualPayeeDialog(this.item);
            },

            validateOnCancel() {
                const self = this;
                self.v$.$touch();
                if (self.v$.$error) throw new Error('Please provide all required fields.');
                return true;
            },

            save(){
                const self = this;
                self.errorMessage = "";
                self.validateFileNumber();
                self.v$.$touch();
                if (self.v$.$error) { return Promise.reject({errorMessage: 'Please provide all required fields and correct errors.'}); }

                let changes = self.getAuditChanges(self.original, self.item, ["gfNo"]);

                let checkDateChanged = _.find(changes, c => c.name == "checkDate");
                if(checkDateChanged && (self.readOnly || self.hadReconciliationID || !self.hasOrdersID || self.disableDates)) return Promise.reject({errorMessage: 'Invalid Operation.'});

                let checkNumberChanged = _.find(changes, c => c.name == "checkNumber");
                if(checkNumberChanged != null && (self.hadReconciliationID || !self.hasOrdersID || (!self.localSecurity.AllowCheckNumberChange && !self.isNew))) return Promise.reject({errorMessage: 'Invalid Operation.'});

                let payeeChanged = _.find(changes, c => c.name == "payee");
                if(payeeChanged && (self.hadReconciliationID || !self.hasOrdersID || self.readOnly)) return Promise.reject({errorMessage: 'Invalid Operation.'});

                let voidDateChanged = _.find(changes, c => c.name == "voidDate");
                if(voidDateChanged && (self.hasStopPayDate || self.hadReconciliationID || !self.hasOrdersID || self.item.isTransfer || self.item.isEFER || self.disableDates)) return Promise.reject({errorMessage: 'Invalid Operation.'});

                let stopPayDateChanged = _.find(changes, c => c.name == "stopPayDate");
                if(stopPayDateChanged && (self.hasVoidDate || self.hadReconciliationID || !self.hasOrdersID || self.item.isTransfer || self.item.isEFER || self.disableDates)) return Promise.reject({errorMessage: 'Invalid Operation.'});

                let reconciliationIDChanged = _.find(changes, c => c.name == "reconciliationID");
                if(reconciliationIDChanged && (self.disableDates || self.disableReconciliation || !self.hasOrdersID || self.isNew)) return Promise.reject({errorMessage: 'Invalid Operation.'});

                if (changes.length == 0) {
                    self.$toast.info({ message: `No Changes Detected` });
                    return Promise.resolve(true);
                }

                let escrowCheckChangeRequest = {
                    escrowCheck: self.item,
                    changeReason: self.changeReason
                };

                let apiPromise = self.$api.EscrowAccountingApi.saveCheck(escrowCheckChangeRequest, changes);
                return self.$rqBusy.wait(apiPromise);
            },

            showCompanyDialog (item) {
                const self = this;
                let original = _.clone(item);
                let onOk = function (e) {
                    self.errorMessage = "";
                    let company = _.get(e, "originalEvent.data", e.data);
                    if (!company)
                        return false;
                    self.item.payeeCompanyID = company.companyID;
                    self.item.payee = company.companyName;
                    self.item.buyerSellerID = null;
                    return true;
                };

                self.$dialog.open({
                    title: "Select Company or Contact",
                    height: "90%",
                    width: "85%",
                    component: CompanyContactLookup,
                    props: {defaultSelectedCompanyId: item.payeeCompanyID},
                    closeOnEsc: true,
                    onOk: onOk,
                });
            },

            showFileContactsDialog(item) {
                const self = this;
                let onOk = (e) => {
                    let contact = e.component.selectedContact;
                    self.item.buyerSellerID = contact.buyerSellerID;
                    self.item.payeeCompanyID = contact.companyID;
                    self.item.payee = contact.name;
                    let hasForwarding = _.parseBool(contact.hasForwardingAddress);
                    if (hasForwarding) {
                        let onYes = (e) => {
                            self.item.useForwardingAddress = true;
                        };
                        let onNo = (e) => {
                            self.item.useForwardingAddress = false;
                        };
                        self.$dialog.confirm("Address Selection", "Use forwarding address?", onYes, onNo, { cancelTitle: 'No', okTitle: 'Yes'});
                    }
                };
                self.$dialog.open({
                    title: "Select File Contact",
                    height: "700",
                    width: "1000",
                    resizable: true,
                    adaptive: false,
                    closeOnEsc: true,
                    component: FileContactSelection,
                    onOk: onOk,
                    props: {
                        ordersID: item.ordersID
                    },
                });
            },

            showManualPayeeDialog(item) {
                const self = this;
                let original = _.clone(item);
                let onOk = (e) => {
                    if(_.isNullOrEmpty(e.component.item.payee))
                        return false;
                    _.assign(self.item, e.component.item);
                    self.item.buyerSellerID = null;
                    self.item.payeeCompanyID = null;
                    e.component.validate();
                    return true;
                };
                self.$dialog.open({
                    title: "Manual Payee",
                    height: "400",
                    width: "900",
                    resizable: true,
                    adaptive: false,
                    closeOnEsc: true,
                    component: CheckWritingManualPayee,
                    onOk: onOk,
                    props: {
                        item: original
                    },
                });
            },

            validateFileNumber(e) {
                const self = this;
                if (self.isNew && !_.isEmpty(self.$refs.fileNumber)) {
                    self.$refs.fileNumber.updateValidation();
                }
            },
        }
    }
</script>
